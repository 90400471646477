<template>
  <div class="page_con">
    <div class="inform center">
      <div>
        고객님의 아이디는 다음과 같습니다.<br />
        <span class="color">{{ userId }}</span>
      </div>
    </div>
    <div class="con_btn">
      <div class="btn_wrap">
        <button class="btn" @click="fnLogin">로그인</button>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-find-id-done-index',
  props: {
    userId: {
      type: [String],
      default: ''
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
